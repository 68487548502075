import React from "react";
import style from "./eventArea.module.css"

const EventArea = () => {
    return (
        <>
            <div className={style.eventArea}>
                <h1>HANGOUT AREA</h1>
            </div>
        </>
    );
}

export default EventArea;
